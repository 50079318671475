import { useState } from 'react'
import emailjs from 'emailjs-com'

const initialState = {
  name: '',
  email: '',
  message: '',
}
export const Contact = (props) => {
  const [{ name, email, message }, setState] = useState(initialState)

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({ ...prevState, [name]: value }))
  }
  const clearState = () => setState({ ...initialState })



  const handleSubmit = (e) => {
    e.preventDefault()
    var templateParams = {
        name: name,
        email: email,
        message: message
    };
    e.target.reset();
    emailjs
      .send(
        'service_k0hggbx', 'template_9jytapw', templateParams, 'iuQxMaFxcrhMMxi_r'
      )
      .then(function(response) {
        console.log('SUCCESS!', response.status, response.text);
        alert("Ihre Nachricht wurde erfolgreich verschickt. Wir werden uns so schnell wie möglich mit Ihnen in Verbindung setzen.");
      }, function(err) {
          console.log('FAILED...', err);
          alert("Es gab einen Fehler beim Versenden der Nachricht. Bitte versuchen Sie es zu einem späterne Zeitpunkt erneut.");
      }
      )
  }
  return (
    <div>
      <div id='contact'>
        <div className='container'>
          <div className='col-md-8'>
            <div className='row'>
              <div className='section-title'>
                <h2>Kontaktieren Sie uns</h2>
                <p>
                Bitte füllen Sie das nachstehende Formular aus, um uns eine E-Mail zu senden, und wir werden
                  uns so schnell wie möglich mit Ihnen in Verbindung setzen.
                </p>
              </div>
              <form name='sentMessage' validate onSubmit={handleSubmit}>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='text'
                        id='name'
                        name='name'
                        className='form-control'
                        placeholder='Name'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                  <div className='col-md-6'>
                    <div className='form-group'>
                      <input
                        type='email'
                        id='email'
                        name='email'
                        className='form-control'
                        placeholder='E-Mail'
                        required
                        onChange={handleChange}
                      />
                      <p className='help-block text-danger'></p>
                    </div>
                  </div>
                </div>
                <div className='form-group'>
                  <textarea
                    name='message'
                    id='message'
                    className='form-control'
                    rows='4'
                    placeholder='Nachricht'
                    required
                    onChange={handleChange}
                  ></textarea>
                  <p className='help-block text-danger'></p>
                </div>
                <div id='success'></div>
                <button type='submit' className='btn btn-custom btn-lg'>
                  Nachricht senden
                </button>
              </form>
            </div>
          </div>
          <div className='col-md-3 col-md-offset-1 contact-info'>
            <div className='contact-item'>
              <h3>Kontaktinformationen</h3>
              <p>
                <span>
                  <i className='fa fa-map-marker'></i> Addresse
                </span>
                {props.data ? props.data.address : 'loading'}<br />
                {props.data ? props.data.address2 : 'loading'}<br />
                {props.data ? props.data.address3 : 'loading'}
              </p>
            </div>
            <div className='contact-item'>
              <p>
                <span>
                  <i className='fa fa-envelope-o'></i> E-Mail
                </span>{' '}
                {props.data ? props.data.email : 'loading'}
              </p>
            </div>
          </div>
          {/* <div className='col-md-12'>
            <div className='row'>
              <div className='social'>
                <ul>
                  <li>
                    <a href={props.data ? props.data.facebook : '/'}>
                      <i className='fa fa-facebook'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.twitter : '/'}>
                      <i className='fa fa-twitter'></i>
                    </a>
                  </li>
                  <li>
                    <a href={props.data ? props.data.youtube : '/'}>
                      <i className='fa fa-youtube'></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            <a href='/impressum.html' rel='nofollow'>
              Impressum
            </a>&nbsp; · &nbsp;
            <a href='/datenschutz.html' rel='nofollow'>
              Datenschutzerklärung
            </a> <br />
            &copy; 8yOne. All rights reserved. <br />
          </p>
        </div>
      </div>
    </div>
  )
}
